import { datadogRum } from '@datadog/browser-rum';

let isInit = false;

export function init(): void {
  if (isInit) {
    return;
  }
  isInit = true;

  if (process.env.REACT_APP_DD_RUM_ENABLED !== 'true') {
    // not enabled, abort
    return;
  }

  // https://docs.datadoghq.com/real_user_monitoring
  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: `siren-microsite-${process.env.REACT_APP_ENV || ''}`,
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_DD_RELEASE_VERSION,
    sessionSampleRate: parseInt(process.env.REACT_APP_DD_RUM_SESSION_SAMPLE_RATE || '100'),
    sessionReplaySampleRate: parseInt(
      process.env.REACT_APP_DD_RUM_SESSION_REPLAY_SAMPLE_RATE || '100',
    ),
    // NOTE: The trackUserInteractions parameter enables the automatic collection
    // of user clicks in your application. Sensitive and private data contained
    // on your pages may be included to identify the elements interacted with.
    trackUserInteractions: true,
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    actionNameAttribute: 'data-action-name',
    defaultPrivacyLevel: 'allow',
    // falls back to datadog domain if proxy not supplied or not enabled
    // ('false' value can be used as escape hatch if env variables can't be empty)
    // TODO: sd - rum in firefox not working; need to use proxy if possible
    // proxy:
    //   DD_PROXY_URL && DD_PROXY_URL !== 'false' ? DD_PROXY_URL : undefined,
  });

  datadogRum.setGlobalContextProperty('app', 'siren');

  datadogRum.startSessionReplayRecording();
}

export const addAction = (name: string, context?: object | undefined): void => {
  if (!isInit) {
    return;
  }
  datadogRum.addAction(name, context);
};
